<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";

import { mapGetters } from "vuex";
import store from "@/state/store";

import SocietiesList from "@/components/societies-list.vue";
export default {
  page: {
    title: "Configurations",
  },
  data() {
    return {
      // title: "Détails événement",
      items: [
        {
          text: "GICAM",
          href: "/",
        },
        {
          text: "Evénements",
          href: "/",
        },
        {
          text: "Détails",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    SocietiesList,
  },
  methods: {},

  async beforeRouteEnter(to, from, next) {
    await store.dispatch("events/findOne", to.params.eventId);

    next();
  },

  computed: {
    ...mapGetters("events", ["currentEvent"]),
    title() {
      return this.currentEvent.name ?? "Détails événement";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <h5 class="mb-2">
      {{ new Date(currentEvent.event_start_date).toLocaleString() }} -
      {{ new Date(currentEvent.event_end_date).toLocaleString() }}
    </h5>

    <div class="row">
      <div class="d-flex align-items-center mb-3">
        <button
          type="button"
          class="btn btn-light btn-xl"
          @click="$router.replace('/')"
        >
          <i class="mdi mdi-arrow-left"></i> Retour
        </button>
      </div>

      <!--end col-->
      <div class="col-xxl-12">
        <SocietiesList />
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
