<script>
import { mapGetters } from "vuex";

import axios from "@/config/axios";
import Swal from "sweetalert2";
import { CountTo } from "vue3-count-to";

import { BASE_URL } from "@/helpers/constants";
import EventServices from "@/services/EventServices";

export default {
  components: {
    CountTo,
  },
  data() {
    return {
      searchQuery: "",
      files: [],
      files_loading_ids: [],
    };
  },
  computed: {
    ...mapGetters("events", ["currentEvent"]),

    usersScanned() {
      return this.documentsMapped.filter((document) => document.scanned);
    },

    usersNotScanned() {
      return this.documentsMapped.length - this.usersScanned.length;
    },

    percentageScanned() {
      return Math.round(
        (this.usersScanned.length / this.documentsMapped.length) * 100
      );
    },

    documentsMapped() {
      return this.currentEvent.members
        .map((document) => {
          return {
            ...document,
            status:
              document.sms_sended || document.email_sended
                ? "success"
                : "pending",
            factor: document.scanned ? 1 : -1,
            factor_date: !document.scanned
              ? new Date(0).getTime()
              : new Date(document.scanned_at).getTime(),
          };
        })
        .sort((d1, d2) => d2.factor - d1.factor)
        .sort((d1, d2) => d2.factor_date - d1.factor_date);
    },
    documentsSuccess() {
      return this.documentsMapped.filter(
        (document) => document.sms_sended || document.email_sended
      );
    },
    documentsError() {
      return this.documentsMapped.filter(
        (document) => !(document.sms_sended || document.email_sended)
      );
    },
    percentageSuccess() {
      return Math.round(
        (this.documentsSuccess.length / this.documentsMapped.length) * 100
      );
    },
    displayedFiles() {
      return this.documentsMapped;
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedFiles.filter((data) => {
          return (
            data.name?.toLowerCase().includes(search) ||
            data.dg?.toLowerCase().includes(search) ||
            data.phone_number?.toLowerCase().includes(search) ||
            data.email?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedFiles;
      }
    },
  },
  methods: {
    handleRefresh() {
      this.$store.dispatch("events/findOne", this.$route.params.eventId);
    },

    handleDownloadScanned() {
      axios({
        url: BASE_URL + "/events/" + this.currentEvent.id + "/download/scanned",
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "gicam-presents-list-" + new Date().getTime() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(
            "Erreur lors du téléchargement du fichier Excel:",
            error
          );
        });
    },

    handleDownloadAll() {
      axios({
        url: BASE_URL + "/events/" + this.currentEvent.id + "/download/all",
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "gicam-all-list-" + new Date().getTime() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(
            "Erreur lors du téléchargement du fichier Excel:",
            error
          );
        });
    },

    handleDownloadAbscents() {
      axios({
        url:
          BASE_URL + "/events/" + this.currentEvent.id + "/download/abscents",
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "gicam-abscents-list-" + new Date().getTime() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(
            "Erreur lors du téléchargement du fichier Excel:",
            error
          );
        });
    },

    async handleResendEmail(file) {
      if (file.status === "success") {
        let resend = false;

        await Swal.fire({
          title: "Voulez-vous vraiment renvoyer le message ?",
          text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, renvoyer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resend = true;
          }
        });

        if (!resend) {
          return;
        }
      }

      this.files_loading_ids.push(file.id);
      try {
        const { data } = await axios.post(BASE_URL + "/resend-email", {
          matricule: file.matricule,
        });
        console.log(data);
        this.handleRefresh();
        this.$saSuccess("Email envoyé", "Le document a été envoyé");
      } catch (error) {
        this.handleRefresh();
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
      this.files_loading_ids = this.files_loading_ids.filter(
        (id) => id !== file.id
      );
    },

    async handleResendSMS(file) {
      if (file.status === "success") {
        let resend = false;

        await Swal.fire({
          title: "Voulez-vous vraiment renvoyer le message ?",
          text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, renvoyer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            resend = true;
          }
        });

        if (!resend) {
          return;
        }
      }

      this.files_loading_ids.push(file.id);
      try {
        const { data } = await axios.post(BASE_URL + "/resend-sms", {
          matricule: file.matricule,
        });
        console.log(data);
        this.handleRefresh();
        this.$saSuccess("SMS envoyé", "Le document a été envoyé");
      } catch (error) {
        this.handleRefresh();
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
      this.files_loading_ids = this.files_loading_ids.filter(
        (id) => id !== file.id
      );
    },

    async handleConfirmUser(file) {
      if (!file.scanned) {
        let confirm = false;

        await Swal.fire({
          html:
            "Voulez-vous vraiment confirmer l'arrivée de <b>" +
            file.name +
            "</b> ?",
          // text: "Le document semble déjà avoir été envoyé",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui, confirmer",
          cancelButtonText: "Non, annuler",
          confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
          cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            confirm = true;
          }
        });

        if (!confirm) {
          return;
        }
      }

      this.files_loading_ids.push(file.id);
      try {
        const { data } = await axios.patch(
          BASE_URL + "/users/" + file.matricule + "/confirm"
        );
        console.log(data);
        this.handleRefresh();
        this.$saSuccess("Confirmation", "Présence de l'entreprise confirmée");
      } catch (error) {
        this.handleRefresh();
        this.$saError("Une erreur est survenue", "Veuillez réessayer");
        console.log(error);
      }
      this.files_loading_ids = this.files_loading_ids.filter(
        (id) => id !== file.id
      );
    },

    async handleOpenEvent() {
      let confirm = false;

      await Swal.fire({
        html: "Confirmer l'activation de l'évènement ? Vous pourrez désormez scanner les codes.",
        // text: "Le document semble déjà avoir été envoyé",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, confirmer",
        cancelButtonText: "Non, annuler",
        confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
        cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          confirm = true;
        }
      });

      if (!confirm) {
        return;
      }

      this.$startLoading();

      await EventServices.start(this.currentEvent.id)
        .then(async ({ data }) => {
          if (data.error === false) {
            await this.$store.dispatch(
              "events/findOne",
              this.$route.params.eventId
            );

            this.$saSuccess("Évènement lancé !");
          } else {
            this.$saError("Erreur lors du lancement !");
          }
        })
        .catch(() => {
          this.$saError("Erreur lors du lancement !");
        });
    },

    async handleCloseEvent() {
      let confirm = false;

      await Swal.fire({
        html: "Confirmer la fermeture de l'évènement ? Vous pourrez ne pourrez plus scanner les codes.",
        // text: "Le document semble déjà avoir été envoyé",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, confirmer",
        cancelButtonText: "Non, annuler",
        confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
        cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          confirm = true;
        }
      });

      if (!confirm) {
        return;
      }

      this.$startLoading();

      await EventServices.finish(this.currentEvent.id)
        .then(async ({ data }) => {
          if (data.error === false) {
            await this.$store.dispatch(
              "events/findOne",
              this.$route.params.eventId
            );

            this.$saSuccess("Évènement terminé !");
          } else {
            this.$saError("Erreur lors de la fermeture !");
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la fermeture !");
        });
    },

    async handleDiffuseEvent() {
      this.$startLoading();

      await EventServices.diffuse(this.currentEvent.id)
        .then(async ({ data }) => {
          if (data.error === false) {
            await this.$store.dispatch(
              "events/findOne",
              this.$route.params.eventId
            );

            this.$saSuccess("Messages et E-mail diffusés !");
          } else {
            this.$saError("Erreur lors de la diffusion !");
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la diffusion !");
        });
    },
  },
};
</script>

<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="diffusionConfirmModal"
    tabindex="-1"
    aria-labelledby="diffusionConfirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="diffusionConfirmModalLabel">
            Confirmation
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Confirmez-vous l'envoi des E-mails et SMS à tous les destinataires ?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click.prevent="handleDiffuseEvent"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3 mb-5">
    <div class="card-header p-0 border-0 bg-soft-light">
      <div class="row g-0 text-center">
        <div
          class=""
          :class="{
            'col-4 col-sm-3': !currentEvent.diffused,
            'col-6 col-sm-3': currentEvent.diffused,
          }"
        >
          <div class="p-3 border border-dashed border-start-0">
            <h5 class="mb-1">
              <count-to
                :duration="2000"
                :startVal="0"
                :endVal="documentsMapped.length"
              ></count-to>
            </h5>
            <p class="text-muted mb-0">Participants</p>
          </div>
        </div>
        <!--end col-->
        <template v-if="currentEvent.diffused">
          <div class="col-6 col-sm-3">
            <div class="p-3 border border-dashed border-start-0">
              <h5 class="mb-1">
                <count-to
                  :duration="2000"
                  :startVal="0"
                  :endVal="documentsSuccess.length"
                ></count-to>
              </h5>
              <p class="text-muted mb-0">Emails envoyé</p>
            </div>
          </div>
          <!--end col-->
          <div class="col-6 col-sm-3">
            <div class="p-3 border border-dashed border-start-0">
              <h5 class="mb-1">
                <count-to
                  :duration="5000"
                  :startVal="0"
                  :endVal="documentsError.length"
                  :class="{
                    'text-danger': documentsError.length > 0,
                    'text-success': documentsError.length === 0,
                  }"
                ></count-to>
              </h5>
              <p class="text-muted mb-0">Échec d'envoi</p>
            </div>
          </div>
          <!--end col-->
          <!-- <div class="col-6 col-sm-3">
            <div class="p-3 border border-dashed border-start-0 border-end-0">
              <h5 class="mb-1 text-success">
                <count-to
                  :duration="2000"
                  :startVal="0"
                  :endVal="percentageSuccess || 0"
                ></count-to
                >%
              </h5>
              <p class="text-muted mb-0">Taux de réussite</p>
            </div>
          </div> -->
          <!--end col-->

          <div v-if="currentEvent.status == 0" class="col-6 col-sm-3">
            <div
              class="p-2 h-100 border border-dashed border-start-0 d-flex justify-content-center align-items-center"
            >
              <b-button
                class="ms-2"
                variant="info"
                @click.prevent="handleOpenEvent"
              >
                Ouvrir l'événement
              </b-button>
            </div>
          </div>
          <div v-if="currentEvent.status == 1" class="col-6 col-sm-3">
            <div
              class="p-2 h-100 border border-dashed border-start-0 d-flex justify-content-center align-items-center"
            >
              <b-button
                class="ms-2"
                variant="danger"
                @click.prevent="handleCloseEvent"
              >
                Fermer l'événement
              </b-button>
            </div>
          </div>
          <div
            v-if="currentEvent.status == 2 && currentEvent.started_at"
            class="col-6 col-sm-3"
          >
            <div
              class="p-2 h-100 border border-dashed border-start-0 d-flex justify-content-center align-items-center"
            >
              <b-button
                class="ms-2"
                variant="primary"
                @click.prevent="handleOpenEvent"
              >
                Reouvrir l'événement
              </b-button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-6 col-sm-3">
            <div class="p-3 h-100 border border-dashed border-start-0">
              <h5 class="mb-1 text-warning">
                {{
                  new Date(currentEvent.event_start_date).toLocaleTimeString()
                }}
              </h5>
              <p class="text-muted mb-0">
                {{
                  new Date(currentEvent.event_start_date).toLocaleDateString()
                }}
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="p-3 h-100 border border-dashed border-start-0">
              <h5 class="mb-1 text-warning">
                {{ new Date(currentEvent.event_end_date).toLocaleTimeString() }}
              </h5>
              <p class="text-muted mb-0">
                {{ new Date(currentEvent.event_end_date).toLocaleDateString() }}
              </p>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div
              class="p-2 h-100 border border-dashed border-start-0 border-end-0 d-flex justify-content-center align-items-center"
            >
              <b-button
                class="ms-2"
                variant="primary"
                data-bs-toggle="modal"
                data-bs-target="#diffusionConfirmModal"
              >
                Diffuser les invitations
              </b-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <div class="row g-4 mb-3">
    <div class="col-sm">
      <div class="d-flex justify-content-sm-end">
        <div class="search-box ms-2">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher..."
            v-model="searchQuery"
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </div>
    </div>
  </div>

  <b-row>
    <b-col md="3">
      <div class="card">
        <div class="card-body p-3" style="font-size: 12px">
          <div>
            <div class="mt-4 text-center">
              <h5 class="mb-1">{{ currentEvent.name }}</h5>
              <p class="text-muted">
                Enregistré le
                {{ new Date(currentEvent.created_at).toLocaleDateString() }}
                à
                {{ new Date(currentEvent.created_at).toLocaleTimeString() }}
              </p>
            </div>
            <div class="table-responsive">
              <table class="table mb-0 table-borderless">
                <tbody>
                  <tr>
                    <th><span class="fw-medium">Diffusion</span></th>
                    <td>
                      <span
                        v-if="currentEvent.diffused == 0"
                        class="badge bg-soft-warning text-warning"
                      >
                        En attente
                      </span>

                      <span v-else>
                        <span>le </span>
                        <span class="text-success">
                          {{
                            new Date(
                              currentEvent.diffused_at
                            ).toLocaleDateString()
                          }}</span
                        >
                        à
                        <span class="text-success">
                          {{
                            new Date(
                              currentEvent.diffused_at
                            ).toLocaleTimeString()
                          }}</span
                        >
                      </span>
                    </td>
                  </tr>

                  <tr v-if="currentEvent.status == 0">
                    <th><span class="fw-medium">Status</span></th>
                    <td>
                      <span class="badge bg-soft-warning text-warning">
                        En attente
                      </span>
                    </td>
                  </tr>

                  <template v-else>
                    <tr>
                      <th><span class="fw-medium">Ouverture</span></th>
                      <td>
                        <span>
                          <span> le </span>
                          <span class="text-success">
                            {{
                              new Date(
                                currentEvent.started_at
                              ).toLocaleDateString()
                            }}</span
                          >
                          à
                          <span class="text-success">
                            {{
                              new Date(
                                currentEvent.started_at
                              ).toLocaleTimeString()
                            }}</span
                          >
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th><span class="fw-medium">Fermeture</span></th>
                      <td>
                        <span v-if="currentEvent.status == 2">
                          <span> le </span>
                          <span class="text-success">
                            {{
                              new Date(
                                currentEvent.closed_at
                              ).toLocaleDateString()
                            }}</span
                          >
                          à
                          <span class="text-success">
                            {{
                              new Date(
                                currentEvent.closed_at
                              ).toLocaleTimeString()
                            }}</span
                          >
                        </span>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-body-->
      </div>
    </b-col>
    <b-col md="9">
      <div class="card">
        <div class="card-header p-0">
          <b-row class="g-0 text-center">
            <div class="col-3">
              <div class="p-3 border border-dashed border-start-0">
                <h5 class="mb-1">
                  {{ documentsMapped.length }}
                </h5>
                <p class="text-muted mb-0">Invités</p>
              </div>
            </div>
            <div class="col-3">
              <div class="p-3 border border-dashed border-start-0">
                <h5 class="mb-1">
                  {{ usersScanned.length }}
                </h5>
                <p class="text-muted mb-0">Présents</p>
              </div>
            </div>
            <div class="col-3">
              <div class="p-3 border border-dashed border-start-0">
                <h5 class="mb-1">
                  {{ usersNotScanned }}
                </h5>
                <p class="text-muted mb-0">Abscents</p>
              </div>
            </div>

            <div class="col-3">
              <div class="p-3 border border-dashed border-start-0">
                <h5 class="mb-1">{{ percentageScanned }}%</h5>
                <p class="text-muted mb-0">Participation</p>
              </div>
            </div>
          </b-row>
        </div>
        <div class="card-body">
          <div class="d-flex align-items-center mb-4">
            <h5 class="card-title flex-grow-1">
              Liste des participants

              <span
                class="badge bg-soft-primary text-primary ms-2"
                v-if="searchQuery"
              >
                {{ resultQuery.length }} résultat(s) pour "{{ searchQuery }}"
                sur
                {{ displayedFiles.length }}
              </span>
            </h5>

            <button class="btn btn-primary" @click.prevent="handleRefresh">
              Actualiser
            </button>

            <router-link
              class="ms-2 btn btn-warning"
              :to="{
                name: 'campagnes-events-settings',
                eventId: currentEvent.id,
              }"
            >
              <i class="ri-settings-2-line me-2 align-bottom"></i>
              Configurations
            </router-link>

            <div class="ms-2 dropdown">
              <a
                href="javascript:void(0);"
                class="btn btn-secondary"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <i class="ri-download-fill me-2 align-bottom"></i>
                Télécharger
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    class="dropdown-item"
                    @click.prevent="handleDownloadScanned"
                  >
                    <i class="ri-download-fill me-2 align-bottom"></i>
                    Liste des présents
                  </a>
                </li>

                <li>
                  <a
                    class="dropdown-item"
                    @click.prevent="handleDownloadAbscents"
                  >
                    <i class="ri-download-fill me-2 align-bottom"></i>
                    Liste des absents
                  </a>
                </li>

                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    @click.prevent="handleDownloadAll"
                  >
                    Toute la liste
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="table-responsive table-card">
                <table
                  class="table table-borderless align-middle"
                  style="font-size: 12px"
                >
                  <thead class="table-light">
                    <tr>
                      <th scope="col" style="min-width: 150px">Entreprise</th>
                      <th scope="col" style="min-width: 150px">
                        Directeur général
                      </th>
                      <th scope="col" class="text-center">Téléphone</th>
                      <th
                        scope="col"
                        class="text-center"
                        style="min-width: 100px"
                      >
                        Email
                      </th>
                      <th scope="col" class="text-center">Envoyé ?</th>
                      <th scope="col" class="text-center">Scanné ?</th>
                      <th scope="col" style="width: 80px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="doc of resultQuery || []" :key="doc.matricule">
                      <td>{{ doc.name }}</td>
                      <td>{{ doc.dg }}</td>
                      <td class="text-center">{{ doc.phone_number }}</td>
                      <td class="text-center">{{ doc.email }}</td>
                      <!-- <td>12 Dec 2021</td> -->
                      <td class="text-center">
                        <span
                          v-if="
                            doc.status === 'loading' ||
                            files_loading_ids.includes(doc.id)
                          "
                          class="spinner-border spinner-border-sm text-primary"
                          role="status"
                          aria-hidden="true"
                        ></span>

                        <span
                          v-else-if="doc.status === 'pending'"
                          class="badge bg-soft-warning text-warning"
                          >En attente</span
                        >

                        <span
                          v-else-if="doc.status === 'error'"
                          class="badge bg-soft-danger text-danger"
                          >Erreur</span
                        >

                        <span
                          v-else-if="doc.status === 'success'"
                          class="badge bg-soft-success text-success"
                          >Envoyé</span
                        >
                      </td>
                      <td class="text-center">
                        <span
                          v-if="!doc.scanned"
                          class="badge bg-soft-warning text-warning"
                          >En attente</span
                        >

                        <span v-else class="text-success"
                          >Le
                          {{ new Date(doc.updated_at).toLocaleString() }}</span
                        >
                      </td>
                      <td>
                        <div class="dropdown">
                          <a
                            href="javascript:void(0);"
                            class="btn btn-soft-secondary btn-sm btn-icon"
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                          >
                            <i class="ri-more-fill"></i>
                          </a>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a
                                class="dropdown-item"
                                @click.prevent="$openQrCode(doc.matricule)"
                                ><i
                                  class="ri-eye-fill me-2 align-bottom text-muted"
                                ></i>
                                Afficher le QR Code
                              </a>
                            </li>

                            <li v-if="!doc.scanned">
                              <a
                                class="dropdown-item"
                                @click.prevent="handleConfirmUser(doc)"
                                ><i
                                  class="ri-check-double-fill me-2 align-bottom text-muted"
                                ></i>
                                Confirmer la présence
                              </a>
                            </li>

                            <li>
                              <hr class="dropdown-divider" />
                            </li>

                            <li>
                              <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                @click.prevent="handleResendEmail(doc)"
                              >
                                <i
                                  class="ri-refresh-line me-2 align-bottom text-muted"
                                ></i>
                                Renvoyer l'email
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                @click.prevent="handleResendSMS(doc)"
                                ><i
                                  class="ri-refresh-line me-2 align-bottom text-muted"
                                ></i>
                                Renvoyer le SMS
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="text-center mt-3">
                      <a href="javascript:void(0);" class="text-success"
                        ><i
                          class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"
                        ></i>
                        Load more
                      </a>
                    </div> -->
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
